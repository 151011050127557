<template>
  <v-row>
    <v-col class="mx-auto" cols="12" md="12" xl="11">
      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <div
          v-if="isLoading"
          class="tw-my-2 tw-flex tw-items-center tw-justify-center"
        >
          <v-progress-circular
            class="tw-my-2"
            :size="70"
            color="primary"
            indeterminate
            absolute
            center
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="c-bg-blue tw--mt-4 tw-h-20">
            <p class="c-yellow-text tw-text-xl tw-ml-5 tw-pt-7">
              {{ getGarageDetails.name }} Report
            </p>
          </div>
          <v-divider></v-divider>
          <!-- next -->

          <v-card-text>
            <h2 class="mb-4 tw-text-lg c-gray-text">
              Reinspect the vehicle below
            </h2>
            <v-divider></v-divider>
            <!-- Header -->
            <div class="tw-grid tw-grid-cols-12 md:tw-gap-4 tw-gap-2 tw-mt-4">
              <p
                class="c-gray-text tw-font-bold tw-col-span-2 md:tw-col-span-1"
              >
                Part
              </p>
              <p
                class="c-gray-text tw-font-bold tw-col-span-2 md:tw-col-span-1"
              >
                Part Type
              </p>
              <p
                class="c-gray-text tw-font-bold tw-col-span-2 md:tw-col-span-1"
              >
                Quantity
              </p>
              <p
                class="c-gray-text tw-font-bold tw-col-span-2 md:tw-col-span-1"
              >
                Bid Amt
              </p>
              <p
                class="c-gray-text tw-font-bold tw-col-span-2 md:tw-col-span-1"
              >
                Supp Amt
              </p>
              <p
                class="c-gray-text tw-font-bold tw-col-span-2 md:tw-col-span-1"
              >
                Final Amt
              </p>
              <p
                class="c-blue-text tw-font-bold tw-col-span-2 md:tw-col-span-1"
              >
                Reinspection Amt
              </p>
              <p
                class="c-blue-text tw-font-bold tw-col-span-2 md:tw-col-span-1"
              >
                Photos
              </p>
              <p
                class="c-gray-text tw-font-bold tw-col-span-2 md:tw-col-span-1"
              >
                VAT
              </p>
              <p
                class="c-blue-text tw-font-bold tw-col-span-2 md:tw-col-span-2"
              >
                Comments
              </p>
            </div>
            <v-divider></v-divider>

            <!-- Rows -->
            <v-row
              v-for="(part, i) in reinspectionReport"
              :key="i"
              class="tw-mb-4"
            >
              <v-form
                v-model="valid"
                ref="form"
                :disabled="getStatus !== 'GARAGE_REINSPECTION_TICKET_RECEIVED'"
              >
                <div
                  class="tw-grid tw-grid-cols-12 md:tw-gap-4 tw-gap-2 tw-items-center"
                >
                  <div class="tw-col-span-2 md:tw-col-span-1">
                    {{ part.part }}
                  </div>
                  <div class="tw-col-span-2 md:tw-col-span-1">
                    {{ part.partType }}
                  </div>
                  <div class="tw-col-span-2 md:tw-col-span-1">
                    {{ part.quantity }}
                  </div>
                  <div class="tw-col-span-2 md:tw-col-span-1">
                    {{
                      part.bidAmount ? part.bidAmount : 'N/A' | formatCurrency
                    }}
                  </div>
                  <div class="tw-col-span-2 md:tw-col-span-1">
                    {{
                      part.supplementaryAmount
                        ? part.supplementaryAmount
                        : 'N/A' | formatCurrency
                    }}
                  </div>
                  <div class="tw-col-span-2 md:tw-col-span-1">
                    {{ part.finalAmount }}
                  </div>
                  <div class="tw-col-span-2 md:tw-col-span-1">
                    <v-text-field
                      type="number"
                      v-model="reinspectionAmounts[i]"
                      @change="setAmounts(reinspectionAmounts[i], i)"
                      :rules="[required('Amount')]"
                    ></v-text-field>
                  </div>
                  <div
                    class="tw-col-span-2 md:tw-col-span-1 tw-flex tw-justify-center tw-items-center"
                  >
                    <template
                      v-if="
                        newPhotos[part.part] && newPhotos[part.part].length > 0
                      "
                    >
                      <v-btn
                        text
                        color="#00A36C"
                        class="tw-font-roboto tw-text-sm tw-font-bold"
                        @click="
                          openUploadModal({
                            label: part.part,
                            photos: newPhotos[part.part],
                            mode: 'edit',
                          })
                        "
                      >
                        Edit Photos
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        icon
                        @click="
                          openUploadModal({
                            label: part.part,
                            mode: 'upload',
                          })
                        "
                      >
                        <v-icon color="#114CAC">mdi-cloud-upload</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="
                          openUploadModal({
                            label: part.part,
                            mode: 'camera',
                          })
                        "
                      >
                        <v-icon color="#114CAC">mdi-camera</v-icon>
                      </v-btn>
                    </template>
                  </div>
                  <div
                    class="tw-col-span-2 md:tw-col-span-1 tw-justify-self-center"
                  >
                    <v-switch
                      v-model="vatArray[i]"
                      @click="calculateVat(i)"
                    ></v-switch>
                  </div>
                  <div class="tw-col-span-12 md:tw-col-span-2">
                    <v-text-field
                      v-model="part.reinspectionComments"
                      placeholder="Type a comment"
                      :rules="[required('Comment')]"
                    ></v-text-field>
                  </div>
                </div>
              </v-form>
              <v-divider></v-divider>
            </v-row>

            <div class="tw-grid tw-grid-cols-12 tw-gap-4 mt-4">
              <div class="tw-col-span-7 tw-text-right">
                <p>Net Total</p>
              </div>
              <div class="tw-col-span-4 tw-text-right">
                <p>Kshs. {{ totalAmt | formatCurrency }}</p>
              </div>
              <div class="tw-col-span-7 tw-text-right">
                <p>VAT 16%</p>
              </div>
              <div class="tw-col-span-4 tw-text-right">
                <p>Kshs. {{ totalVat | formatCurrency }}</p>
              </div>
              <div class="tw-col-span-7 tw-text-right">
                <span class="tw-text-lg tw-font-bold">GRAND TOTAL</span>
              </div>
              <div class="tw-col-span-4 tw-text-right">
                <span class="tw-text-lg tw-font-bold">
                  Kshs. {{ getGrandTotal | formatCurrency }}
                </span>
              </div>
            </div>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-text>
            <h2 class="c-gray-text mb-4 tw-text-lg">Client Details</h2>
            <v-divider></v-divider>
            <v-row class="mt-4">
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Assessment No:</v-col>
                  <v-col>{{ getBookingId }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Date Created:</v-col>
                  <v-col>{{
                    this.bookingInfo.arrivedAt | formatToHuman
                  }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Claim Number:</v-col>
                  <v-col>{{ getBidDetails.claimNumber }}</v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Policy No:</v-col>
                  <v-col> {{ getPolicyDetails.policyNumber }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Insured’s Name:</v-col>
                  <v-col>{{ getClientDetails.name }}</v-col>
                </v-row>
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <h2 class="c-gray-text mb-4 tw-text-lg">Vehicle Details</h2>
            <v-divider></v-divider>
            <v-row class="mt-4">
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Registration No:</v-col>
                  <v-col>{{ getVehicleDetails.registrationNumber }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Make:</v-col>
                  <v-col>{{ getBidDetails.make }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Model:</v-col>
                  <v-col>{{ getBidDetails.model }}</v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Vehicle Color:</v-col>
                  <v-col>{{ getBidDetails.color }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">YOM:</v-col>
                  <v-col>{{ getBidDetails.yearOfManufacture }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Vehicle Type:</v-col>
                  <v-col>{{ getBidDetails.bodyType }}</v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Engine No:</v-col>
                  <v-col> {{ getBidDetails.engineNumber }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Chassis No:</v-col>
                  <v-col>{{ getBidDetails.chassisNumber }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="tw-font-bold">Mileage:</v-col>
                  <v-col>{{ getBidDetails.mileage }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-divider class="my-5"></v-divider>

            <!-- Photo Display -->
            <PhotoDisplay
              :checklist_photos="checklistPhotos"
              title="Reinspection Photos"
              @view-photo="openPhotoViewer"
              @edit-photo="openPhotoEditor"
              comment="The attached photos showcase the results of the vehicle's re-inspection, highlighting its condition post-repair. These images serve as a visual record of the work completed. Please examine the photos carefully."
              @delete-photo="handlePhotoRemoved"
            />
            <v-divider></v-divider>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <p class="c-blue-text">Assessor's Signature</p>
                <p>{{ getAssessorName }}</p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col align="end" class="my-2">
                <div>
                  <v-btn
                    v-show="getStatus === 'GARAGE_REINSPECTION_TICKET_RECEIVED'"
                    color="warning"
                    @click="submitReport"
                    :loading="btnLoader"
                    :disabled="!valid"
                    >Submit Report</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
          <!-- Upload Photo Modal -->
          <UploadPhotoModal
            ref="uploadPhotoModal"
            :isOpen="showModal"
            :mode="uploadMode"
            :label="currentLabel"
            :available="currentSelection"
            :photo="currentPhotos"
            @close="closeUploadModal"
            @photo-added="handlePhotoAdded"
            @photo-removed="handlePhotoRemoved"
            @photo-edited="handlePhotoEdited"
          />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios'
import { formatToHuman } from '@/utils/time'
import { formatCurrency } from '@/utils/helpers'
import validations from '@/utils/Validations'
import UploadPhotoModal from '../../../components/assessment-center/bids-submitted/UploadPhotoModal.vue'
import PhotoDisplay from '../../../components/assessment-center/bids-submitted/UploadedPhotos.vue'
import reinspectionMixins from '@/mixins/reinspectionMixins'

export default {
  name: 'ReinspectionForm',
  components: { PhotoDisplay, UploadPhotoModal },
  mixins: [reinspectionMixins],
  data() {
    return {
      valid: false,
      titles: [
        'Part',
        'Part Type',
        'Quantity',
        'Bid Amount',
        'Supp Amount',
        'Comments',
        'VAT',
      ],
      isLoading: false,
      btnLoader: false,
      extComments: null,
      bookingInfo: {},
      amounts: [],
      vatArray: [],
      finalAmounts: [],
      totalAmt: 0,
      totalVat: 0,
      ...validations,
      isValid: false,
      search: '',
      fetchReinspectionsUrl: '',
      reinspectionDetails: [],
      reinspectionReport: [],
      reinspectionAmounts: [],
    }
  },
  computed: {
    photos() {
      return this.reinspectionDetails.biddingForm.photos
    },
    getStatus() {
      return this.reinspectionDetails.status
    },
    getAssessorName() {
      return this.bookingInfo.externalAssessor.name
    },
    getBookingId() {
      return this.$route.params.bookingId
    },
    getBidDetails() {
      return this.bookingInfo.biddingTemplate
    },
    getGarageDetails() {
      const garageId = this.reinspectionDetails.garageId
      let garageDetails = ''
      this.garages.forEach((item) => {
        if (item.id === garageId) {
          garageDetails = item
        }
      })
      return garageDetails
    },
    getClientDetails() {
      return this.bookingInfo.clientInfo
    },
    getPolicyDetails() {
      return this.bookingInfo.policyInfo
    },
    getVehicleDetails() {
      return this.bookingInfo.vehicleInfo
    },
    getGrandTotal() {
      return this.totalAmt + this.totalVat
    },
  },
  methods: {
    openPhotoEditor({ label, photos }) {
      this.openUploadModal({
        label,
        photos,
        mode: 'edit',
      })
    },
    resetPayloadVat() {
      this.reinspectionReport.forEach((item) => {
        item.vat = false
      })
    },
    getAmountsTotal() {
      let total = this.finalAmounts.reduce((acc, curr) => {
        return acc + (+curr.amount || 0)
      }, 0)
      this.totalAmt = total
    },
    getVatTotal() {
      let total = this.finalAmounts.reduce((acc, curr) => {
        return acc + (+curr.vat || 0)
      }, 0)
      this.totalVat = total
    },
    setAmounts(value, index) {
      if (!this.finalAmounts[index]) {
        this.reinspectionReport[index].reinspectedAmount =
          Math.round(value).toString()
        this.finalAmounts[index] = {
          amount: Math.round(value),
          vat: false,
          totalAmt: value,
        }
      } else {
        this.finalAmounts[index].vat = false
        this.finalAmounts[index].totalAmt = value
        this.vatArray[index] = false
        this.finalAmounts[index].amount = Math.round(value)
        this.reinspectionReport[index].reinspectedAmount =
          Math.round(value).toString()
        this.calculateVat(index)
      }
      this.getAmountsTotal()
    },
    calculateVat(index) {
      let finalAmt
      let isVat = this.vatArray.map((item, i) => {
        if (i === index) {
          return item
        }
      })
      finalAmt = this.reinspectionReport.map((item, i) => {
        if (i === index) {
          return item.reinspectedAmount
        }
      })
      if (isVat[index] === true && finalAmt !== '') {
        const vatAmt = Math.round(this.getVat(this.finalAmounts[index].amount))
        this.finalAmounts[index].vat = vatAmt.toString()
        this.finalAmounts[index].totalAmt =
          +this.finalAmounts[index].amount + +vatAmt
        this.reinspectionAmounts[index] = Math.round(
          this.finalAmounts[index].totalAmt
        ).toString()
        this.reinspectionReport[index].vat = true
        this.getVatTotal()
      } else if (isVat[index] === false && finalAmt !== '') {
        this.finalAmounts[index].totalAmt -= this.finalAmounts[index].vat
        this.reinspectionReport[index].vat = false
        this.reinspectionAmounts[index] = Math.round(
          this.finalAmounts[index].totalAmt
        ).toString()
        this.finalAmounts[index].vat = 0
        this.getVatTotal()
      }
      return finalAmt
    },
    async getReinspection() {
      this.isLoading = true
      this.search = this.$route.params.bookingId
      try {
        const res = await axios.get(
          `/motor-assessment/api/garages/re-inspection/${this.$store.state.auth.user.sub}?pageNumber=1&size=10&bookingIdSearchText=${this.search}`
        )
        this.reinspectionDetails = res.data.data[0]
        this.reinspectionReport =
          this.reinspectionDetails.biddingForm.reinspectionReport
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        this.$store.commit('SNACKBAR', {
          state: 'failed',
          message:
            err.response?.data?.msg || 'Failed to fetch reinspection data',
          variant: 'error',
        })
      }
    },
    async getBooking() {
      try {
        const res = await axios.get(
          `/motor-assessment/api/booking/${this.getBookingId}`
        )
        if (res.data.success && res.data.data !== null) {
          this.bookingInfo = res.data.data
        }
      } catch (err) {
        this.$store.commit('SNACKBAR', {
          state: 'failed',
          message: err.response?.data?.msg || 'Failed to fetch booking data',
          variant: 'error',
        })
      }
    },
    async viewform() {
      this.isLoading = true
      this.reinspectionReport.forEach((item) => {
        this.reinspectionAmounts.push(item.reinspectedAmount)
        this.finalAmounts.push({
          amount: item.reinspectedAmount,
          vat: item.vat ? this.getVat(item.reinspectedAmount) : 0,
          totalAmt: item.reinspectedAmount,
        })
        this.vatArray.push(item.vat)
      })
      await this.getAmountsTotal()
      await this.getVatTotal()
      this.isLoading = false
    },

    async submitReport() {
      this.btnLoader = true

      let reinspectionPhotos = this.checklistPhotos.flatMap((photoGroup) =>
        photoGroup.photos.map((photo) => ({
          comment: photo.comment || '',
          image_url: photo.image_url || '',
          image_base_64: photo.image_base_64 || '',
          available: photo.available || false,
        }))
      )
      let payload = {
        bookingId: this.getBookingId,
        garageId: this.getGarageDetails.id,
        reinspection: this.reinspectionReport,
        reinspectionPhotos: reinspectionPhotos,
      }
      try {
        const res = await axios.post(
          `/motor-assessment/api/garages/re-inspection/update`,
          payload
        )
        if (res.data.success) {
          this.$store.commit('SNACKBAR', {
            state: 'success',
            message: res.data.msg,
            variant: 'success',
          })
          this.$router.push('/major-accident')
        }
      } catch (err) {
        this.$store.commit('SNACKBAR', {
          state: 'failed',
          message: err.response?.data?.msg || 'Failed to submit report',
          variant: 'error',
        })
      } finally {
        this.btnLoader = false
      }
    },
  },
  async beforeMount() {
    try {
      await this.getReinspection()
      await this.fetchGarages()
      await this.getBooking()

      if (this.getStatus !== 'GARAGE_REINSPECTION_TICKET_RECEIVED') {
        this.viewform()
      } else {
        this.resetPayloadVat()
        this.isLoading = false
      }
    } catch (err) {
      this.$store.commit('SNACKBAR', {
        state: 'failed',
        message: err.response?.data?.msg || 'Initialization failed',
        variant: 'error',
      })
    }
  },
  filters: {
    formatToHuman,
    formatCurrency,
  },
}
</script>
