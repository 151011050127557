import axios from 'axios'

export default {
  data() {
    return {
      showModal: false,
      uploadMode: '',
      currentLabel: '',
      currentPhotos: [],
      newPhotos: {},
      checklistPhotos: [],
      currentSelection: true,
      vatRate: 16,

      garages: [],
    }
  },
  methods: {
    openUploadModal({ label, photos = [], mode }) {
      const partIndex = this.reinspectionReport
        ? this.reinspectionReport.findIndex((part) => part.part === label)
        : -1

      if (partIndex !== -1) {
        this.currentSelection =
          this.reinspectionReport[partIndex].available || true
      } else {
        this.currentSelection = true
      }

      this.uploadMode = mode
      this.currentLabel = label
      this.currentPhotos = photos

      if (mode === 'edit' && photos.length > 0) {
        const photoToEdit = photos[0]
        this.currentPhotos = {
          label,
          image_base_64: photoToEdit.image_base_64 || photoToEdit.image_url,
          image_url: photoToEdit.image_url || '',
          available: photoToEdit.available || false,
          comment: photoToEdit.comment || '',
        }
      } else {
        this.currentPhotos = null
      }
      this.showModal = false
      this.$nextTick(() => {
        this.showModal = true
      })
    },

    closeUploadModal() {
      this.showModal = false
      this.uploadMode = ''
      this.currentLabel = ''
      this.currentPhotos = []
    },

    handlePhotoAdded(data) {
      const { label, photos } = data
      if (!this.newPhotos[label]) {
        this.newPhotos[label] = []
      }
      const photoEntries = photos.map((photo) => ({
        label,
        comment: photo.comment || 'No comment provided',
        image_url: photo.url || '',
        image_base_64: photo.image_base_64,
        available: this.currentSelection,
      }))
      this.newPhotos[label] = [...this.newPhotos[label], ...photoEntries]
      this.updateChecklistPhotos()
    },

    handlePhotoRemoved(removedPhoto) {
      const label = removedPhoto.label
      if (this.newPhotos[label]) {
        this.newPhotos[label] = this.newPhotos[label].filter(
          (photo) => photo.image_base_64 !== removedPhoto.image_base_64
        )
        if (this.newPhotos[label].length === 0) {
          delete this.newPhotos[label]
        }
        this.updateChecklistPhotos()
      }
    },

    handlePhotoEdited(data) {
      const { label, photo } = data
      if (!this.newPhotos[label]) {
        return
      }
      photo.available = this.currentSelection
      this.newPhotos[label] = this.newPhotos[label].map((existingPhoto) => {
        if (
          existingPhoto.image_base_64 === photo.image_base_64 ||
          existingPhoto.image_url === photo.image_url
        ) {
          return {
            ...existingPhoto,
            ...photo,
          }
        }
        return existingPhoto
      })
      this.updateChecklistPhotos()
    },

    updateChecklistPhotos() {
      this.checklistPhotos = Object.keys(this.newPhotos).map((label) => ({
        label,
        photos: this.newPhotos[label],
      }))
    },

    openPhotoViewer(photo) {
      if (photo) {
        this.$store.commit('SNACKBAR', {
          showing: true,
          text: 'Opening photo...',
          color: 'info',
        })
      }
    },

    getVat(amount) {
      let vatAmt = amount * (this.vatRate / 100)
      return vatAmt
    },

    async fetchGarages() {
      try {
        const res = await axios.get('/motor-assessment/api/garages')
        this.garages = res.data.data
      } catch (err) {
        this.$store.commit('SNACKBAR', {
          state: 'failed',
          message: err.response?.data?.msg || 'Failed to fetch garages',
          variant: 'error',
        })
      }
    },
  },
}
